import AddCards from "./Card";
import "./stylesheet.css";
function App() {
  return (
    <div>
      <header className="card">
        <h2 className="c">Links to some of my React Apps</h2>
        <p>
          Most of the apps below are assignments in various React course I've
          taken. <br />
          <hr />
          <div style={{ color: "red", textAlign: "center" }}>
            Click on the arrows on the left for more info
          </div>{" "}
          <hr />
        </p>
      </header>
      <AddCards />
    </div>
  );
}

export default App;
