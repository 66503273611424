// list order summary, url, detail
const data = [
  [
    `ADSB Receivers`,
    "https://adsb.fjs.app/",
    `This link brings up a menu where you can access my three ADSB receivers.
    Can also be accessed at adsb.fjs.app`,
  ],
  [
    "Advice",
    "https://advice.felixs.us/",
    `Small app that provides advice when the button is clicked.  Can also
    be accessed at advice.fjs.app`,
  ],
  [
    "Datecounter",
    "https://datecounter.fjs.app/",
    `App that allows you to enter a number of days and it will tell you the
    date that many days from now.  Can also be accessed at datecounter.fjs.app`,
  ],
  [
    "Flashcards",
    "https://flashcards.fjs.app/",
    `This is a flashcard app that allows you to enter a card and see the answser
    when you click on the card.  Can also be accessed at flashcards.fjs.app`,
  ],
  [
    "Polynomial Plotter",
    "https://poly.fjs.app/",
    `This is a simple polynomial plotter. `,
  ],
  [
    "Date Add",
    "https://dateadd.fjs.app/",
    `Pick a date and then a number of days to add to that date.  The result will be displayed. `,
  ],
  [
    "Date Difference",
    "https://datediff.fjs.app/",
    `Two date pickers and displays the difference between the two dates. `,
  ],
  [
    "WiFi QR Code Generator",
    "https://wifiqr.fjs.app/",
    `This app will produce a QR code that can be scanned to connect to a WiFi network. `,
  ],
];
export default data;
